import React from 'react';

class NotFound extends React.Component {
    render(){

        return(
            <div>Not found</div>
        )
    }
}
export default NotFound;