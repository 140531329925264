import React from "react";
import api from "../util/api";

class About extends React.Component {
    constructor(props) {
        super(props);

        // let data = {
        //     lokacija: { LokacijaID: 1, Naziv: "Neka ulica", Adresa: "Neka adresa" },
        //     uloga: { UlogaID: 2, Naziv: "Naziv uloge", Prava: "Neka prava" },
        // };

        //let data = { LokacijaID: 1, Naziv: "Neka ulica", Adresa: "Neka adresa" };

        //api.post("user/test", data, this.success);

        // let url = "http://localhost:31026/camsystem/createtrans";
        // let data = { Transaction: null ,  User: { Username: "apiUserLA", Password: "ApIuser322" }};

        // fetch(url, {
            
        //     method: "POST",
        //     headers: {
        //         Accept: "application/json, text/plain, */*",
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(data)
        // })
        //     .then((res) => {return res.json()})
        //     .then((res) => this.success(res));
    }

    success(data) {
        
    }

    checkStatus(data) {
        
    }

    handleResponse(data) {
        
    }

    render() {
        return <div>About...</div>;
    }
}
export default About;
