import { Grow, Alert, Autocomplete, Box, Button, Divider, FormControl, Grid, IconButton, InputAdornment, ListItem, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback } from "react";
import ComponentContainer from "./componentContainer";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import LineIcon from "react-lineicons";
import Util from "../util/util";
import api from "../util/api";
import globalContext from "./globalContext";
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from "@mui/icons-material/Search";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function UnosTroskaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formEvidencija = props.evidencija;
    const klijenti = props.klijenti;
    const predmeti = props.predmeti;
    const firmaValute = props.firmaValute;
    const tipoviNaplatnosti = props.tipoviNaplatnosti;
    const tipoviOporezivosti = props.tipoviOporezivosti;
    const setCanceled = props.setCanceled;
    const setFormOpened = props.setFormOpened;
    const tipNaplatnostiNaplatnoBoja = props.tipNaplatnostiNaplatnoBoja;
    const tipNaplatnostiNenaplatnoBoja = props.tipNaplatnostiNenaplatnoBoja;
    const evidencijaUPrenesenoRazdobljeMessage = props.evidencijaUPrenesenoRazdobljeMessage;
    const unsavedChanges = props.unsavedChanges;

    const [loading, setLoading] = useState(true);
    const [klijent, setKlijent] = useState(null);
    const [predmet, setPredmet] = useState(null);
    const [opis, setOpis] = useState("");
    const [iznos, setIznos] = useState("0.00");
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [tipNaplatnosti, setTipNaplatnosti] = useState(null);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [datum, setDatum] = useState(new Date(props.date));
    const [formValid, setFormValid] = useState(false);
    const [povijestTroskova, setPovijestTroskova] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const historyOpened = Boolean(anchorEl);
    const [povijestFilter, setPovijestFilter] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [evidencijaUPrenesenoRazdobljeAlert, setEvidencijaUPrenesenoRazdobljeAlert] = useState(false);

    useConstructor(() => {
        if (formEvidencija) {
            if (formEvidencija.EvidencijaDetalj && formEvidencija.EvidencijaDetalj.length > 0) {
                let evidencijaDetalj = formEvidencija.EvidencijaDetalj.at(0);
                predmeti.forEach(p => {
                    if (p.PredmetID == evidencijaDetalj.ReferencaID) {
                        setPredmet(p);
                        klijenti.forEach(k => {
                            if (k.KlijentID == p.KlijentID) {
                                setKlijent(k);
                            }
                        });
                    }
                });
                firmaValute.forEach(fv => {
                    if (fv.FirmaValutaID == evidencijaDetalj.FirmaValutaID) {
                        setFirmaValuta(fv);
                    }
                });
                tipoviNaplatnosti.forEach(tn => {
                    if (tn.TipNaplatnostiID == evidencijaDetalj.TipNaplatnostiID) {
                        setTipNaplatnosti(tn);
                    }
                });
                tipoviOporezivosti.forEach(to => {
                    if (to.TipOporezivostiID == evidencijaDetalj.TipOporezivostiID) {
                        setTipOporezivosti(to);
                    }
                });
            
                let value = evidencijaDetalj.Iznos;
                var decimals = countDecimals(value.toString());
                if (decimals == 0) {
                    value = value + ".00";
                } else if (decimals == 1) {
                    value = value + "0";
                }
                setIznos(value.toString());
            }
            setOpis(formEvidencija.Opis);
            setDatum(new Date(formEvidencija.PocetakRada));
        } else {
            setTipNaplatnosti(tipoviNaplatnosti.at(0));
            setFirmaValuta(props.defaultFirmaValuta);
            setTipOporezivosti(props.defaultTrosakTipOporezivosti);
        }
        api.get("evidencija/getpovijesttroskova/" + global.drugiZaposlenik.KorisnikID, loadSuccess, loadFail);
    });

    function loadSuccess(data) {
        setPovijestTroskova(data);
        setLoading(false);
    }

    function loadFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save(action) {
        setLoading(true);
        setCanceled(false);
        let evidencijaDetalj = [{
            EvidencijaDetaljID : formEvidencija ? formEvidencija.EvidencijaDetalj.at(0).EvidencijaDetaljID : -1,
            EvidencijaID : formEvidencija ? formEvidencija.EvidencijaID : -1,
            ReferencaID : predmet.PredmetID,
            Iznos : parseFloat(iznos),
            FirmaValutaID : firmaValuta.FirmaValutaID,
            TipNaplatnostiID : tipNaplatnosti.TipNaplatnostiID,
            TipOporezivostiID : tipOporezivosti.TipOporezivostiID
        }]
        let updatedEvidencija = {
            EvidencijaID : formEvidencija ? formEvidencija.EvidencijaID : -1,
            //KorisnikID : global.drugiZaposlenik.KorisnikID,
            KorisnikID : formEvidencija ? formEvidencija.KorisnikID : global.drugiZaposlenik.KorisnikID,
            TipEvidencijeID : 2,
            TipPredmetnostiID : 1,
            PocetakRada : datum,
            KrajRada : datum,
            Opis : opis,
            EvidencijaDetalj : evidencijaDetalj,
            ObrisaniDetaljiIDs : [],
            PovezaniZadaci: [],
            EvidencijaUPrenesenoRazdobljeAction: action
        };
        api.post("evidencija/insert", updatedEvidencija, saveSuccess, saveFail);
    }

    function saveSuccess(data) {
        props.loadEvidencije(data);
        setLoading(false);
        unsavedChanges.current = false;
        setFormOpened(false);
    }

    function saveFail(data) {
        if (data == -2) {
            setEvidencijaUPrenesenoRazdobljeAlert(true);
            setLoading(false);
        } else {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        }
    }

    useEffect(() => {
        let checkValid = predmet && opis && (parseFloat(iznos) >= 0) && firmaValuta && tipNaplatnosti && tipOporezivosti && datum && !isNaN(new Date(datum));
        setFormValid(checkValid); 
    }, [predmet, iznos, firmaValuta, tipNaplatnosti, tipOporezivosti, datum, opis]);

    function countDecimals(value) {
        if (value.indexOf(".") == -1) {
            return 0;
        } else if (Math.floor(parseFloat(value)) !== parseFloat(value)) {
            return value.toString().split(".")[1].length;
        }
    }


    function handleIznosFocusOut() {
        // if (!isNaN(parseFloat(iznos)) && parseFloat(iznos) > 0) {
        //     setIznos(parseFloat(iznos));
        // } else {
        //     setIznos(0);
        // }

        if (iznos == "" || iznos.charAt(0) == '-') {
            setIznos("0.00");
        } else if (!isNaN(parseFloat(iznos))) {
            let value = iznos;
            let i = 0;
            while (iznos.length > i + 1 && iznos.charAt(i) == '0' && iznos.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setIznos(value);
        }

    }


    function handleHistoryClick(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleHistoryClose(event) {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (predmet && (!klijent || predmet.KlijentID != klijent.KlijentID)) {
            klijenti.forEach(k => {
                if (k.KlijentID == predmet.KlijentID) {
                    setKlijent(k);
                }
            })
        }
        // if(predmet){
        //     setTipOporezivosti(tipoviOporezivosti.find(to=>to.TipOporezivostiID == predmet.TipOporezivostiID));
        // }
    }, [predmet]);

    useEffect(() => {
        if (klijent && predmet && klijent.KlijentID != predmet.KlijentID) {
            setPredmet(null);
        }
    }, [klijent]);

    const handleKeyPressShorcuts = useCallback(
        (event) => {

            if (event.altKey) {
                let charCode = String.fromCharCode(event.which).toLowerCase();
                if (charCode == "p") {
                    event.preventDefault();
                    if (!loading && predmet && opis && (parseFloat(iznos) >= 0) && firmaValuta && tipNaplatnosti && tipOporezivosti && datum && !isNaN(new Date(datum))) {
                        save(null);
                    }
                }
                if (charCode == "o") {
                    event.preventDefault();
                    if (!loading) {
                        setCanceled(true);
                        unsavedChanges.current = false;
                        setFormOpened(false);                 
                    }
                }                
                 
            }

        }, [
            loading, 
            predmet, 
            iznos, 
            firmaValuta, 
            tipNaplatnosti, 
            tipOporezivosti, 
            datum, 
            opis,
            formEvidencija
        ]
    );

    const handleEsc = useCallback(
        (event) => {

            if(event.keyCode == 27){
                event.preventDefault();
                if (!loading) {
                    setCanceled(true);
                    unsavedChanges.current = false;
                    setFormOpened(false);                 
                }
            }                        

        },
        [loading]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressShorcuts);
        document.addEventListener("keyup", handleEsc);

        return () => {
            document.removeEventListener("keydown", handleKeyPressShorcuts);
            document.removeEventListener("keyup", handleEsc);
        };
    }, [handleKeyPressShorcuts]);
    
    return (
        <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
            <ComponentContainer loading={loading}>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{display:"flex", justifyContent:"center", marginTop:"15px"}}>
                        <DatePicker onChange={(v) => {setDatum(v); unsavedChanges.current = true;}} label="Datum unosa troška" value={datum} inputFormat="dd.MM.yyyy" maxDate={new Date()} renderInput={(params) => <TextField style={{ width: "230px" }} {...params} size="small" />} /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal 
                                options={klijenti} 
                                onChange={(e,v) => {if (v) {setKlijent(v); unsavedChanges.current = true;}}}
                                getOptionLabel={option => option.Broj + " " + option.Naziv}
                                //disableClearable
                                value={klijent}
                                ListboxProps={{ style: { maxHeight: '15rem' } }}
                                renderOption={(props, option) => {
                                    return (
                                    <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                        {option.Broj + " - " + option.Naziv}
                                    </li>
                                    );
                                }}
                                filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                                autoHighlight
                                renderInput={(params) => <TextField autoFocus sx={{width:"100%", input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} size="small" {...params} label="Klijent" error={!klijent} required />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal 
                                options={klijent ? predmeti.filter(p => p.KlijentID == klijent.KlijentID) : predmeti} 
                                onChange={(e,v) => {if (v) {setPredmet(v); unsavedChanges.current = true; setTipOporezivosti(tipoviOporezivosti.find(to=>to.TipOporezivostiID == v.TipOporezivostiID));}}}
                                getOptionLabel={option => option.Broj + " - " + option.Naziv}
                                noOptionsText={klijent ? "Za odabranog klijenta ne postoji niti jedan predmet." : "Ne postoji niti jedan predmet."}
                                value={predmet}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '15rem' } }}
                                //disableClearable
                                renderOption={(props, option) => {
                                    return (
                                    <li {...props} key={"predmet" + option.PredmetID} style={{color: option.TipStanjaPredmetaID == 1 ? (option.TipNaplateID == 3 ? "green" : (option.TipNaplateID == 2 ? "magenta" : "black")) : "red"}}>
                                        {option.Broj + " - " + option.Naziv}
                                    </li>
                                    );
                                }}
                                filterOptions={(options, state) => options.filter(option => (option.Broj + " " + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                                renderInput={(params) => <TextField sx={{width:"100%", input:{color: predmet && predmet.TipStanjaPredmetaID != 1 ? "red" : (predmet && predmet.TipNaplateID == 3 ? "green" : (predmet && predmet.TipNaplateID == 2 ? "magenta" : "black"))}}} size="small" {...params} label="Predmet" error={!predmet} required />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={11}>
                        <FormControl fullWidth>
                            <TextField value={opis || ""} label="Opis" onChange={e => {unsavedChanges.current = true;setOpis(e.target.value);}} inputProps={{ spellCheck: "false" }} multiline rows={2} error={!opis}></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                        <IconButton onClick={handleHistoryClick}><HistoryIcon></HistoryIcon></IconButton>
                    </Grid>
                    {predmet && predmet.TipNaplateID == 3 && predmet.Iznos == null && !loading ? (
                        <Grow in={predmet && predmet.TipNaplateID == 3 && predmet.Iznos == null}>
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    Za odabrani predmet s fiksnom naplatom nije definiran iznos u definiciji predmeta. Trošak se može evidentirati, ali ga neće biti moguće prebaciti u račun prije definicije fiksnog iznosa naplate.
                                </Alert>
                            </Grid>
                        </Grow>
                    ) : null}
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <TextField type="number" inputProps={{lang:"hr-HR"}} label="Iznos" size="small" value={iznos} onChange={e => {unsavedChanges.current = true; setIznos(e.target.value);}} onBlur={handleIznosFocusOut} error={parseFloat(iznos) < 0} required></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal 
                                options={firmaValute} 
                                onChange={(e,v) => {if (v) {unsavedChanges.current = true; setFirmaValuta(v);}}}
                                getOptionLabel={option => option.FirmaValutaNaziv}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.FirmaValutaID}>
                                            {option.FirmaValutaNaziv}
                                        </li>
                                    );
                                }}
                                value={firmaValuta}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Valuta" error={!firmaValuta} required />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal 
                                options={tipoviNaplatnosti} 
                                onChange={(e,v) => {if (v) {unsavedChanges.current = true; setTipNaplatnosti(v);}}}
                                getOptionLabel={option => option.Naziv}
                                value={tipNaplatnosti}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.TipNaplatnostiID}>
                                            <Brightness1Icon sx={{ color: "rgb(" + (option.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon>
                                            <span>&nbsp;&nbsp;{option.Naziv}</span>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField size="small" sx={{width:"100%"}} {...params} label="Tip naplatnosti" error={!tipNaplatnosti} required InputProps={{
                                        ...params.InputProps,
                                        startAdornment: tipNaplatnosti ? <InputAdornment position="end"><Brightness1Icon sx={{ color : "rgb(" + (tipNaplatnosti.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon></InputAdornment> : null
                                    }}/>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal
                                options={tipoviOporezivosti} 
                                onChange={(e,v) => {if (v) {unsavedChanges.current = true; setTipOporezivosti(v);}}}
                                getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                                value={tipOporezivosti}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.TipOporezivostiID}>
                                            {option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                                        </li>
                                    );
                                }}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Tip oporezivosti" error={!tipOporezivosti} required />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                            <Button onClick={() => save(null)} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !formValid}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Sp&#818;remi</Button>
                            <Button variant="outlined" color="error" onClick={() => {setCanceled(true); unsavedChanges.current = false; setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>O&#818;dustani</Button>
                        </Box>
                    </Grid>
                </Grid>

                <Menu
                    anchorEl={anchorEl}
                    open={historyOpened}
                    onClose={handleHistoryClose}
                    PaperProps={{ style: { maxWidth: "50vw" } }} 
                >
                    <ListItem key="povijestFilter">
                        <FormControl fullWidth>
                            <TextField 
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }} 
                                value={povijestFilter} 
                                onChange={e => setPovijestFilter(e.target.value)} 
                                size="small"
                                InputProps={{endAdornment:(<InputAdornment position="end"><SearchIcon></SearchIcon></InputAdornment>)}}
                                disabled={povijestTroskova.length == 0}
                                onKeyDown={e => e.stopPropagation()}
                            ></TextField>
                        </FormControl>
                    </ListItem>
                    <Divider></Divider>
                    {povijestTroskova.length > 0 ? (
                        povijestTroskova.filter(opis => opis.toLowerCase().includes(povijestFilter.toLowerCase())).map((opis, index) => {
                            return <Tooltip title={opis} enterDelay={200} enterNextDelay={200} key={"tooltip" + index}>
                                    <MenuItem onClick={() => {setOpis(opis)}} key={index}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {opis}
                                        </div>
                                    </MenuItem>
                                </Tooltip>
                        })
                    ) : (
                        <div style={{textAlign:"center"}}>Ne postoji niti jedan evidentirani trošak.</div>
                    )}
                </Menu>

                {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
                {evidencijaUPrenesenoRazdobljeAlert ? <AlertDialog title="Upozorenje" buttonsLayout="vertical" icon={<WarningAmberIcon style={{marginRight:"15px", color:"red"}}></WarningAmberIcon>} message={<div dangerouslySetInnerHTML={{__html: evidencijaUPrenesenoRazdobljeMessage}}></div>} cancelHidden={false} confirmHidden={false} okHidden={false} cancelLabel={"Odustani"} cancelAction={() => setEvidencijaUPrenesenoRazdobljeAlert(false)} confirmLabel="Spremi evidenciju" confirmAction={() => save(1)} okLabel="Spremi evidenciju i ne prikazuj ovu poruku ubuduće" okAction={() => save(2)}></AlertDialog> : null}
            </ComponentContainer>
        </LocalizationProvider>
    );
}

export default UnosTroskaForm;