import React from "react";
import LineIcon from "react-lineicons";

class Spinner extends React.Component {
    render() {
        return (
            <div className="spinner">
                <div className="spinner-icon">
                    <LineIcon size="sm" name="spiner-solid lni-is-spinning" />
                </div>
            </div>
        );
    }
}
export default Spinner;
